export type Venue = {
  id: number;
  name: string;
  slug: string;
  total: number;
};

export type Category = {
  id: number;
  name: string;
  slug: string;
  description: string;
  ingress: string;
  total: number;
};

export type Organizer = {
  id: number;
  name: string;
  slug: string;
  description: string;
  total: number;
};

export type TagGroup = {
  id: number;
  slug: string;
  name: string;
  tags: Tag[];
};

export type Tag = {
  id: number;
  name: string;
  slug: string;
  total: number;
};

export type Group = {
  id: number;
  name: string;
  slug: string;
  description: string;
  ingress: string;
  total: number;
};

export type Material = {
  path: string;
  name: string;
};

export type Schedule = {
  id: number;
  from: string;
  to: string;
  venue_id: number | null;
  venue_name: string | null;
};

export type Meta = {
  id: string;
  key: string;
  value: string;
};

export type Content = {
  id: string;
  format: string;
  content: string;
  order: number;
};

export type Statistics = {
  can_enroll: boolean;
  seats_available: number;
  seats_for_reserve: number;
  has_seat: boolean;
  has_seat_reserve: boolean;
};

export type EventCover = {
  url: string;
  alt?: string;
  copyright?: string;
};

export enum ViewType {
  MultiEvent = 'multi-course',
  SingleEvent = 'single-course',
}

export type MultiEvent = {
  id: number;
  name: string;
  slug: string;
  url: string;
  cover: EventCover | undefined;
  ingress: string;
  start_date: string;
  view_type: ViewType;
  tenant_id: number;
};

export type FormQuestionOption = {
  id: number;
  name: string;
  linked_question_ids: number[] | null;
};

export type FormQuestionTag = {
  id: number;
  name: string;
  linked_question_ids: number[] | null;
};

export type FormQuestion = {
  id: number;
  name: string;
  is_linked_question: boolean;
  description?: string;
  question_type: 'SELECT' | 'TEXT' | 'RADIO' | 'TAG' | 'SHORT_TEXT';
  options: FormQuestionOption[];
  tags: FormQuestionTag[];
  is_required: boolean;
  allow_override_question_deletion: boolean;
};

export type FormStep = {
  id: number;
  name: string;
  is_required: boolean;
  title: string;
  description: string;
  notice: string | null;
  questions: FormQuestion[];
};

export type Form = {
  id: number;
  name: string;
  title?: string;
  description: string;
  is_required: boolean;
  info_notice: string | null;
  use_previous_reply: boolean;
  ask_again_probability: number;
  type: string;
  steps: FormStep[];
};

export type EventForms = {
  id: number;
  type: string;
};

export enum FormAnswerType {
  TEXT = 'TEXT',
  SHORT_TEXT = 'SHORT_TEXT',
  SELECT = 'SELECT',
  RADIO = 'RADIO',
  TAG = 'TAG',
}

export type FormAnswerText = {
  type: FormAnswerType.TEXT;
  value: string;
};

export type FormAnswerShortText = {
  type: FormAnswerType.SHORT_TEXT;
  value: string;
};

export type FormAnswerRadio = {
  type: FormAnswerType.RADIO;
  value: boolean;
};

export type FormAnswerTag = {
  type: FormAnswerType.TAG;
  value: number;
};

export type FormAnswerSelect = {
  type: FormAnswerType.SELECT;
  value: number;
};

export type FormAnswerSelection = {
  id?: number;
  question_id: number;
} & (FormAnswerText | FormAnswerRadio | FormAnswerSelect | FormAnswerTag | FormAnswerShortText);

export type FormAnswer = {
  target_id: number;
  replyId?: number;
  deleteWhenLinkedEventEndsOverride: number[];
  selection: FormAnswerSelection[];
};

export enum DeprecatedFormAnswerType {
  TEXT = 'TEXT',
  SELECT = 'SELECT',
}

export type DeprecatedFormQuestionOption = {
  id: number;
  name: string;
  linked_question_id?: number;
};

export type DeprecatedFormQuestion = {
  id: number;
  name: string;
  is_linked_question: boolean;
  description?: string;
  question_type: 'SELECT' | 'TEXT';
  options: DeprecatedFormQuestionOption[];
  is_required: boolean;
};

export type DeprecatedForm = {
  id: number;
  name: string;
  title?: string;
  description: string;
  is_required: boolean;
  type: string;
  questions: DeprecatedFormQuestion[];
};

export type DeprecatedFormAnswerSelect = {
  type: DeprecatedFormAnswerType.SELECT;
  option_id: number;
};

export type DeprecatedFormAnswerText = {
  type: DeprecatedFormAnswerType.TEXT;
  value: string;
};

export type DeprecatedFormAnswerSelection = {
  option_id?: number;
  question_id: number;
  value?: string;
};

export type DeprecatedFormAnswer = {
  target_id: number;
  selection: DeprecatedFormAnswerSelection[];
};

export type EventNew = {
  id: number;
  name: string;
  route: string;
  cover: EventCover | undefined;
  background_image: string;
  kicker: string;
  content: { json: any; html: string };
  start_at: string;
  end_at: string;
  view_type: ViewType;
  tenant_id: string;
  venue: Venue | null;
};

export type Event = {
  id: number;
  name: string;
  slug: string;
  url: string;
  cover: EventCover | undefined;
  ingress: string;
  state: string;
  start_date: string;
  for_sale_from: string;
  for_sale_to: string;
  independent_record: boolean;
  meta: Meta[] | null;
  content: Content[];
  sub_events: Event[] | null | undefined;
  group_id: number | null;
  categories: number[];
  organizers: number[];
  view_type: ViewType;
  statistics: Statistics;
  materials: Material[];
  schedule: Schedule[];
  tenant_id: number;
  organizer?: { id: string; old_id: number; name: string; slug: string };
  forms: EventForms[];
};

export type TagCloudProps = {
  tagcloud: { name: string | number; id: number }[];
};
